<template>
  <div class="myseries">
    <div class="myseries_center">
      <header>
        <p>系列课程管理</p>
        <el-button type="primary" @click="createserise" size="mini">建立系列课程</el-button>
      </header>
      <nav>
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="课程名称" prop="seriseName">
            <el-input v-model="resourceForm.seriseName" @change="querylist" clearable size="mini"></el-input>
          </el-form-item>
          <el-form-item label="课程标签" prop="labelId">
            <el-select v-model="resourceForm.labelId" size="mini" clearable>
              <el-option v-for="item in serisedata" :key="item.id" :label="item.name" :value="item.id" clearable></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-form-item prop="startTime">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.startTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.endTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="querylist" size="mini">查询</el-button>
            <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
          </el-form-item>
        </el-form>
      </nav>
      <aside>
        <el-button type="primary" @click="deldata(null)" size="mini">批量删除系列课程</el-button>
      </aside>
      <main>
        <!-- border -->
        <el-table :data="resTabDatas" style="width: 100%" @selection-change="handleSelectionChange" stripe>
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" fixed="left" label="序号" :index="computeTableIndex"></el-table-column>
          <el-table-column prop="seriseName" label="系列课程标题" min-width="200" fixed="left" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="labelName" label="系列课程标签" width="200" align="center"></el-table-column>
          <el-table-column prop="courseCount" label="包含课程数量" width="200" align="center"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="200" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="编辑系列课程" placement="top">
                <el-button size="mini" circle @click="editdata(scope.row)">
                  <i class="btn_edit"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="查看系列课程" placement="top">
                <el-button size="mini" circle @click="viewSerise(scope.row)">
                  <i class="btn_view"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除系列课程" placement="top">
                <el-button size="mini" circle @click="deldata(scope.row)">
                  <i class="btn_del"></i>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column fixed="right" label="操作" width="240">
            <template slot-scope="scope">
              <el-button type="success" size="mini" @click="editdata(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="viewdata(scope.row)">查看</el-button>
              <el-button type="info" size="mini" @click="deldata(scope.row)">删除</el-button>
            </template>
          </el-table-column>-->
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[10, 20, 30, 40 ,50]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 系列课程标签
      serisedata: null,
      // 表单
      resourceForm: {
        seriseName: null,
        labelId: null,
        startTime: null,
        endTime: null,
      },
      // 表格数据
      resTabDatas: null,
      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: null, //总数
    };
  },
  created() {
    this.querySeriseLable();
    this.querySerise();
  },
  methods: {
    // 获取系列课程的标签
    async querySeriseLable() {
      await this.$Http.querySeriseLable().then((res) => {
        console.log(res, "获取系列课程");
        if (res.status == 200) {
          this.serisedata = res.data;
        }
      });
    },
    // 获取系列课程列表
    async querySerise() {
      const { data, status } = await this.$Http.querySerise({
        id: null, //系列id
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
        sortId: 1, //排序ID(1-上传时间，2-点赞量，3-收藏量)
        sortType: 2, //排序类型（1-正序，2-倒序）
        ...this.resourceForm,
        // seriseName: seriseName, //系列名称
        // startTime: startTime, //开始时间
        // endTime: endTime, //结束时间
      });
      if (status == 200) {
        console.log(data, status, "系列课程列表");
        if (data == null) {
          this.resTabDatas = null;
          this.total = 0;
        } else {
          this.resTabDatas = data.list;
          this.total = data.total;
        }
      }
    },
    // 查看
    viewSerise(row) {
      console.log(row.id, "系列课程页面");
      this.$router.push({
        path: "/series",
        query: {
          id: row.id,
        },
      });
    },
    //编辑
    editdata(row) {
      console.log(row, "编辑课程");
      this.$router.push({
        path: "/upseries",
        query: {
          id: row.id,
        },
      });
      // this.$store.commit("userinfo/editseriesRowId", row.id);
      // this.$nextTick(() => {
      //   this.$store.commit("userinfo/editshowSeries", true);
      // });
    },
    // 删除
    async deldata(row) {
      console.log(row, "删除");
      let idList = [];
      if (row == null) {
        for (let a = 0; a < this.multipleSelection.length; a++) {
          idList.push(this.multipleSelection[a].id);
        }
      } else {
        idList.push(row.id);
      }

      this.$confirm("确定删除系列课程？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(idList, "idListidList");
          const { data, status } = await this.$Http.deleteSeriseCourse({
            seriseIdList: idList, //系列id集合
          });
          if (status == 200) {
            console.log(data, status, "删除系列");
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.querySerise();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查询
    querylist() {
      this.querySerise();
      // this.resetfrom("resourceRef");
    },
    // 重置
    resetfrom(formName) {
      console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.querySerise();
    },

    // 创建系列课程
    createserise() {
      console.log("建立系列课程");
      this.$router.push({
        path: "/upseries",
      });
    },

    //

    // 多选
    handleSelectionChange(val) {
      console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.querySerise();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.querySerise();
    },
  },
};
</script>
<style lang="scss" scoped>
.myseries {
  .myseries_center {
    header {
      display: flex;
      justify-content: space-between;
      height: 70px;
      background: #ffffff;
      padding: 0 20px;
      p {
        // width: 108px;
        // height: 24px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        // line-height: 21px;
        line-height: 70px;
      }
      .el-button {
        height: 30px;
        margin: 20px 0 0 0;
      }
    }
    nav {
      margin-top: 10px;
      background-color: #fff;
      padding: 15px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    aside {
      background-color: #fff;
      margin-top: 12px;
      padding: 18px 20px;
    }
    main {
      background-color: #fff;
      padding: 0 20px;
      i {
        display: inline-block;
        width: 19px;
        height: 18px;
      }
      .btn_sumbit {
        background: url("../../../../assets/userinfo/submitbtn.png") no-repeat;
      }
      .btn_edit {
        background: url("../../../../assets/userinfo/editbtn.png") no-repeat;
      }
      .btn_view {
        background: url("../../../../assets/userinfo/viewbtn.png") no-repeat;
      }
      .btn_del {
        background: url("../../../../assets/userinfo/delbtn.png") no-repeat;
      }
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
  }
}
</style>